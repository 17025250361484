import {
  Body,
  Box,
  Button,
  buttonLink,
  Card,
  Heading,
  InputSliderField,
  LocalizedString,
  NonNegative,
  Positive,
  PositiveInteger,
  Stack,
  unsafeLocalizedString,
  unsafeNonNegative,
  unsafePositiveInteger,
} from "design-system";
import { boolean, option, taskEither } from "fp-ts";
import { constNull, constVoid, flow, identity, pipe } from "fp-ts/function";
import { Reader } from "fp-ts/Reader";
import { useRef, useState } from "react";
import {
  Currency,
  foldCurrency,
  unsafeNonNegativeInteger,
} from "../../globalDomain";
import {
  LocaleKey,
  useFormatCurrency,
  useFormatMessage,
  useFormatMoneyAmount,
} from "../../intl";
import { useCommand } from "../../useAPI";
import { FilePDFDialog } from "../../Common/Dialogs/FilePDFDialog/FilePDFDialog";
import * as api from "../api";
import * as documentAPI from "../../Common/documentAPI";
import { Tenor } from "./Tenor";
import { Option } from "fp-ts/Option";
import { ChooseBrokerButton } from "./ChooseBrokerButton";
import { useAppContext } from "../../useAppContext";
import { useIs3PChannel, useIsInPersonChannel } from "../../useChannel";

type Props = {
  initialOffer: api.LoanOfferWithLimits;
  onContinue: (loanOffer: api.LoanOfferWithLimits, show3P?: boolean) => unknown;
};

interface ConfiguratorValues {
  amount: NonNegative;
  tenor: PositiveInteger;
}

function useDebounceAction<I>(
  action: Reader<I, void>,
  delay: number
): Reader<I, void> {
  const timeoutRef = useRef<Option<number>>(option.none);

  return (input: I) => {
    pipe(
      timeoutRef.current,
      option.fold(constVoid, currentTimeout => {
        window.clearTimeout(currentTimeout);
      })
    );

    timeoutRef.current = option.some(
      window.setTimeout(() => {
        action(input);
        timeoutRef.current = option.none;
      }, delay)
    );
  };
}

interface ClampAndRoundParams {
  min: number;
  max: number;
  step: Positive;
}

function makeClampAndRound(
  params: ClampAndRoundParams
): Reader<number, number> {
  return amount => {
    const rounded = Math.round(amount / params.step) * params.step;
    return Math.max(Math.min(rounded, params.max), params.min);
  };
}

export function Configurator(props: Props) {
  const formatMessage = useFormatMessage();
  const formatMoneyAmount = useFormatMoneyAmount(unsafeNonNegativeInteger(0));
  const formatCurrency = useFormatCurrency();
  const updateLoanOffer = useCommand(api.updateLoanOffer);
  const representativeExampleTemplate = useCommand(documentAPI.templates);
  const representativeExampleContent = useCommand(documentAPI.content);

  const [currentLoanOffer, setCurrentLoanOffer] = useState({
    ...props.initialOffer,
    isLoading: false,
  });

  const [
    showRepresentativeExampleModal,
    setShowRepresentativeExampleModal,
  ] = useState(false);

  const amountStep = pipe(
    currentLoanOffer.currency,
    foldCurrency({
      CZK: () =>
        currentLoanOffer.maxAmount - currentLoanOffer.minAmount < 20000
          ? 500
          : 5000,
      EUR: () =>
        currentLoanOffer.maxAmount - currentLoanOffer.minAmount < 1000
          ? 100
          : 200,
    }),
    unsafePositiveInteger
  );

  const tenorStep = unsafePositiveInteger(3);

  const clampAndRoundAmount = makeClampAndRound({
    min: currentLoanOffer.minAmount,
    max: currentLoanOffer.maxAmount,
    step: amountStep,
  });

  const clampAndRoundTenor = makeClampAndRound({
    min: currentLoanOffer.minTenor,
    max: currentLoanOffer.maxTenor,
    step: tenorStep,
  });

  const debounceUpdate = useDebounceAction((values: ConfiguratorValues) => {
    setCurrentLoanOffer(offer => ({
      ...offer,
      isLoading: true,
    }));

    pipe(
      updateLoanOffer({
        amount: pipe(
          values.amount,
          clampAndRoundAmount,
          NonNegative.decode,
          option.fromEither
        ),
        tenor: pipe(
          values.tenor,
          clampAndRoundTenor,
          PositiveInteger.decode,
          option.fromEither
        ),
      }),
      taskEither.chain(updatedOffer =>
        taskEither.fromIO(() =>
          setCurrentLoanOffer({
            ...updatedOffer,
            isLoading: false,
          })
        )
      )
    )();
  }, 750);

  const onAmountUpdate: Reader<NonNegative, void> = amount => {
    setCurrentLoanOffer(offer => ({
      ...offer,
      amount,
    }));

    debounceUpdate({
      amount,
      tenor: currentLoanOffer.tenor,
    });
  };

  const onTenorUpdate: Reader<PositiveInteger, void> = tenor => {
    setCurrentLoanOffer(offer => ({
      ...offer,
      tenor,
    }));

    debounceUpdate({
      amount: currentLoanOffer.amount,
      tenor,
    });
  };

  function formatLabelContent(
    currency: Currency,
    message: LocaleKey
  ): Reader<number, LocalizedString> {
    return value =>
      formatMessage(
        message,
        formatMoneyAmount(
          { amount: unsafeNonNegative(value), currency },
          identity
        )
      );
  }

  const {
    config: { enableCashLoanId3PInBranch },
  } = useAppContext();
  const isInPersonChannel = useIsInPersonChannel();
  const is3PChannel = useIs3PChannel();

  return (
    <Stack column grow shrink units={12}>
      <Heading size="medium" weight="medium" align="center">
        {formatMessage("StandardLoan.Landing.Configurator.Title")}
      </Heading>
      <Stack column units={3}>
        <Card>
          <Stack column grow shrink units={10} divider>
            <Box column vAlignContent="center">
              <Heading size="x-small" weight="medium">
                {formatMessage("PreApprovedLoanSaleConfigurator.amountLabel")}
              </Heading>
              <InputSliderField
                value={currentLoanOffer.amount}
                onChange={val =>
                  pipe(
                    isNaN(val) ? 0 : val,
                    flow(unsafeNonNegative, onAmountUpdate)
                  )
                }
                min={currentLoanOffer.minAmount}
                max={currentLoanOffer.maxAmount}
                step={amountStep}
                minLabelContent={formatLabelContent(
                  currentLoanOffer.currency,
                  "PreApprovedLoanSaleConfigurator.minAmountLabel"
                )}
                maxLabelContent={formatLabelContent(
                  currentLoanOffer.currency,
                  "PreApprovedLoanSaleConfigurator.maxAmountLabel"
                )}
                name="amount"
                issues={option.none}
                label={unsafeLocalizedString("")}
                onBlur={constVoid}
                rightContent={formatCurrency(currentLoanOffer.currency)}
                disabled={currentLoanOffer.isLoading}
              />
            </Box>
            <Tenor
              currentLoanOffer={currentLoanOffer}
              onChange={onTenorUpdate}
            />
          </Stack>
        </Card>
        <Body size="small" weight="regular">
          {[
            formatMessage("PreApprovedLoanSaleConfigurator.tenorFooter1"),
            buttonLink(
              () => setShowRepresentativeExampleModal(true),
              formatMessage("PreApprovedLoanSaleConfigurator.tenorFooter2")
            ),
          ]}
        </Body>
        {enableCashLoanId3PInBranch && isInPersonChannel && !is3PChannel ? (
          <Stack units={4} column hAlignContent="center" shrink>
            <Button
              size="default"
              variant="primary"
              label={formatMessage("StandardLoan.Landing.LoanWithout3P")}
              action={() => props.onContinue(currentLoanOffer, false)}
            />
            <ChooseBrokerButton
              heading={formatMessage("StandardLoan.Landing.LoanWith3P")}
              action={() => props.onContinue(currentLoanOffer, true)}
              buttonLabel={formatMessage(
                "Mortgage.Dashboard.Overview.brokerForBankerApp.choose"
              )}
            />
          </Stack>
        ) : (
          <Box hAlignContent="right">
            <Button
              size="default"
              variant="primary"
              label={formatMessage("Continue")}
              action={() => props.onContinue(currentLoanOffer, false)}
            />
          </Box>
        )}
      </Stack>
      {pipe(
        showRepresentativeExampleModal,
        boolean.fold(constNull, () => (
          <FilePDFDialog
            title={formatMessage(
              "PreApprovedLoanSaleConfigurator.representativeExampleModalTitle"
            )}
            onDismiss={() => setShowRepresentativeExampleModal(false)}
            file={pipe(
              representativeExampleTemplate({
                docsets: ["representativeExample"],
              }),
              taskEither.chain(items =>
                representativeExampleContent({ docId: items[0].docId })
              )
            )}
            downloadUrl={option.none}
          />
        ))
      )}
    </Stack>
  );
}
