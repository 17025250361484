import { Box } from "design-system";
import { PhoneAndEmailFlow } from "../../PhoneAndEmailVerification/PhoneAndEmailFlow";
import { CoApplicantInput } from "../../globalDomain";
import { Option } from "fp-ts/Option";
import { TaskEither } from "fp-ts/TaskEither";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { PageHeading } from "../../Common/PageHeading/PageHeading";
import { foldFlowType, StandardLoanFlowType } from "../domain";
import { pipe } from "fp-ts/function";
import { useFormatMessage } from "../../intl";
import {
  GenerateOTP,
  VerifyOTP,
} from "../../PhoneAndEmailVerification/PhoneVerification/PhoneVerification";

import { OtpGenerationError } from "../../PhoneAndEmailVerification/api";

import { useCommand } from "../../useAPI";
import { linkGenerationNow } from "../api";

type Props = {
  initialPromoCode: Option<NonEmptyString>;
  onPhoneVerified: (phoneNumber: string) => TaskEither<unknown, unknown>;
  onComplete: () => unknown;
  generateOTP: GenerateOTP;
  verifyOTP: VerifyOTP;
  hideInstructions?: boolean;
  flowType: StandardLoanFlowType;
  onMaxNumberChangesReached?: (error: OtpGenerationError) => unknown;
} & CoApplicantInput;

export function PhoneAndEmailVerification(props: Props) {
  const formatMessage = useFormatMessage();

  const generateLinkNow = useCommand(linkGenerationNow);

  const headingWhenNon3PFlow = () => (
    <PageHeading
      title={formatMessage(
        "StandardLoan.PhoneAndEmail.emailAndPhoneVerificationTitle"
      )}
      hideOnMobile
    />
  );

  return (
    <Box column grow shrink>
      {pipe(
        props.flowType,
        foldFlowType({
          when3P: () => (
            <PageHeading
              title={formatMessage(
                "StandardLoan.PhoneAndEmail.emailAndPhoneVerificationTitle"
              )}
              description={formatMessage(
                "StandardLoan.PhoneAndEmail.emailAndPhoneVerification3PDescription"
              )}
              hideOnMobile
            />
          ),
          whenInPerson: headingWhenNon3PFlow,
          whenTLSAgent: headingWhenNon3PFlow,
          whenHomeBanking: headingWhenNon3PFlow,
          whenSmartBanking: headingWhenNon3PFlow,
          whenPWSRemote: headingWhenNon3PFlow,
        })
      )}

      <PhoneAndEmailFlow
        initialPromoCode={props.initialPromoCode}
        onPhoneVerified={props.onPhoneVerified}
        onComplete={props.onComplete}
        coApplicant={props.coApplicant}
        generateOTP={props.generateOTP}
        verifyOTP={props.verifyOTP}
        linkGenerationNow={generateLinkNow}
        hideInstructions={props.hideInstructions}
        onMaxNumberChangesReached={props.onMaxNumberChangesReached}
      />
    </Box>
  );
}
